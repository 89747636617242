.wrapperAgentForm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent;
  // background-color: rgba($color: #000000, $alpha: 0.1);
  background: url('../../assets/images/right_preview.jpg')  rgba(0, 0, 0, 0.4);
  background-size: cover;
  background-blend-mode: darken;

  .wrapperAgentForm_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: max-content;
    padding: 80px 34px 80px 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    border-radius: 10px;
    @media screen and (max-width: 750px) {
      padding: 40px 21px 40px 15px;
    }
    @media screen and (max-width: 300px) {
      padding: 40px 0;
    }
    form {
      margin-top: 30px;
    }
  }
}

.thanksText{
  margin: 140px auto;
  width: 100%;
  text-align: center;
}
