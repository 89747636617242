.ReactModal__Content.ReactModal__Content--after-open {
  inset: 40px 0 !important;
  border: none !important;
  z-index: 10;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  #closeBtn {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 0;
    right: 0;
  }
  h2 {
    margin-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
  }
  .modalContent {
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 30px;

    p {
      font-weight: 600;
    }
    p:first-child {
      margin-right: 10px;
    }
  }
}
.backButton {
  cursor: pointer;
  top: 3px;
  position: absolute;
  left: 16px;
  background-color: #06aede;
  padding: 7px 31px;
  border: none;
  color: #fff;
  font-size: 1em;
  border-radius: 2px;

  &:hover {
    background-color: #1d3c5a;
  }
}

@media screen and (max-width: 750px) {
  .backButton {
    display: none;
  }
}
