#promotinal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 20px;
  padding: 30px 50px;
  max-width: 980px;
  margin: auto;
  position: relative;
  z-index: 2;
  @media screen and (max-width: 940px) {
    padding: 20px;
  }

  h2 {
    text-align: center;
    font-size: 50px;
    line-height: 64px;
    font-weight: 300;
    text-shadow: 3.993px 3.009px 2px hsla(0, 0%, 84.7%, 0.9);
    z-index: 2;
    color: #3cafde;
    @media screen and (max-width: 989px) {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 39px !important;
      line-height: 44px !important;
      font-weight: 300;
    }
  }
  h3 {
    text-align: center;
    font-family: "Quicksand", serif;
    font-size: 2em;
    line-height: 76px;
    color: #1d3c5b;
    font-weight: 400;
    padding: 0 0 30px;
    z-index: 2;

    span {
      font-size: 1.4em;
    }

    @media screen and (max-width: 989px) {
      line-height: 46px;
    }
    @media only screen and (max-width: 480px) {
      line-height: 36px;
    }
  }
  &.prompop {
    padding-bottom: 6px;
    @media only screen and (max-width: 480px) {
      h3 {
        font-size: 1.1em;

        span {
          font-size: 1.6em;
        }
      }

      .content .link_list_wrap .link_list.promot {
        margin-left: 0px;

        .link_item {
          transform: scale(1.1);
          margin-bottom: 0px;
        }
      }



      .txtContainer p {
        padding: 0 !important;
        font-size: 13px !important;
      }
    }
  }
  
  h4 {
    text-align: center;
    font-family: "Quicksand", serif;
    font-size: 1.5em;
    line-height: 76px;
    color: #1d3c5b;
    font-weight: 400;
    z-index: 2;
    @media only screen and (max-width: 480px) {
      line-height: 36px;
    }
  }
  .txtContainer p {
    text-align: center;
    font-size: 18px;
    line-height: 36px;
    padding: 0 0 20px;
    @media screen and (max-width: 989px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  .imgContainer {
    margin-top: 40px;
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: center;
  }
  .imgContainer img {
    width: 48%;
    margin-bottom: 60px;
    @media screen and (max-width: 940px) {
      width: 100%;
    }
  }
}
#promotinal:before {
  content: "";
  width: 570px;
  height: 240px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAkcAAAD5BAMAAADfMozoAAAAIVBMVEVMaXEAAAD////////m4NTm4NXn4dXm4NTo4tbn4dXo4tay3zPIAAAACnRSTlMAAQEC+/z9/f7+1FV0ywAAAaZJREFUeF7t1zFKA1EUhtHEAUEXIOgSrARB0MolZAkp1DpLcAlpROysgrpKwac8uAhXAjN3wPNOkfykCNzuW3wsvt7xa/vsM6xJ51ub549f66DPsEadw67Nm+1fjuRIHgAAUEy79fkTa2uBmxftuixwAQAABG5etPsHriNN+QAAAARuHmsCN5yhIHABAAAEblq0owWuI43wAAAABG7oL4GbHKk+cAEAAARujLWiwHWkPR4AAIDAjYVVH7iOlDwAAACBG2pt2M0ycB3plwcAACBwY0TNP3AdyQMAgDFdtjFchBnWPGbR386r3c62SVMVtdtD+3bYfugzrGnmyapd5a7Nq9sww5porhb/8QEAgMAVuLFol+8CNy3a5b3ABQCAQgJX4OZFK3DzohW4AABQT+AK3J6wAjctWoELAABUBq7AzYtW4OZFK3ABAID6wBW4ebMK3KRZBS4AAFAXuAI3b1aB26tU4AIAAFWBK3DzohW4edEKXAAAoD5wBW5epQI3dqfABQAA6gNX4MbS7EvgvrRvR993eG7zdBPWpHN4avN6FWZYE83NJ7roEOtWmlSUAAAAAElFTkSuQmCC') repeat 0 0;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  z-index: 1;
  @media screen and (max-width: 989px) {
    top: 10px;
    height: 100px;
    width: 100%;
  }
}
#promotinal.prompop::before {
  display: none;
}


