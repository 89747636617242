.wrapperModal {

  background: url('../images/right_preview.jpg')  rgba(0, 0, 0, 0.4);
  background-size: cover;
  background-blend-mode: darken;
  height: 100vh;
  display: flex;
  align-items: center;

  &.reviews {
    .closeButton {
      top: 64px;
      right: calc(50% - 396px);
    }

    @media screen and (max-width: 980px) {
      .closeButton {
        top: 3px;
        right: 6px;
      }
    }

  }

  .modalContainerRef {
    width: 540px;
    margin: auto;
    padding: 30px 24px 30px 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding-top: 40px;
    padding-bottom: 80px;

    &.reviews {
      width: 760px;
      padding-top: 40px;
      max-height: calc(100vh - 160px);
      overflow: auto;
      position: relative;
    
    }
    @media screen and (max-width: 980px) {
      &.reviews {
        width: 94%;
        max-height: calc(100vh - 30px);
        overflow: hidden;
      }
    }
    @media screen and (max-width: 500px) {
      width: 330px;
    }
    @media screen and (max-width: 300px) {
      width: 280px;
      padding: 30px 0;
    }
    .modal_container {
      .title_success {
        text-align: center;
        font-size: 35px;
        font-weight: 500;
        margin-bottom: 0;
        color: #19569c;
      }
    }
    .thanksText {
      width: 100%;
      margin: 200px auto;
      text-align: center;
      .title_success {
        text-align: center;
        font-size: 35px;
        font-weight: 500;
        margin-bottom: 0;
        color: #19569c;
      }
    }

    .form {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @media screen and (max-width: 500px) {
        margin-top: 10px;
      }
    }

    .inputs_col {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
      margin-bottom: 40px;

      & > * {
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
      }

      @media screen and (max-width: 650px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      @media screen and (max-width: 500px) {
        & > * {
          flex: 0 0 100%;
          max-width: 100%;
        }

        gap: 10px;
      }
    }

    .submit {
      font-family: inherit !important;
      color: white !important;
      width: calc(50% - 20px);
      height: 50px;
      font-size: 16px !important;
      font-weight: 600 !important;
      background: linear-gradient(90deg, #1ddee1, #43beef);

      &:hover {
        background: linear-gradient(45deg, #1d3c5a, #1d3c5a);
      }
      // background: linear-gradient(45deg, #06aede, #06aede);
      // border-radius: 1px !important;

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }

    .numberInput {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;
    }
  }
}
