.ourLatestWork {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: #19569c;
    margin-bottom: 40px;
    font-weight: 500;
    text-shadow: none;
    text-align: center;
    @media only screen and (max-width: 980px) {
      margin-bottom: 0;
    }
  }
  h3 {
    color: #19569c;
    margin-bottom: 20px;
    font-weight: 500;
    text-shadow: none;
    text-align: center;
    @media only screen and (max-width: 980px) {
      margin-top: 20px;
    }
  }
  .sliderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 130px;
    height: 0;

    .testimonial-slider {
      width: 90%;
      padding: 0;
      margin: 0;

      .box {
        width: 400px;
        padding: 5px;
        border: none;
        box-shadow: none;
        @media only screen and (max-width: 1440px) {
          padding: 0;
        }
        img {
          border-radius: 50px;
          padding: 5px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          @media only screen and (max-width: 980px) {
            border-radius: 15px;
          }
        }
      }
      .box::before {
        display: none;
      }
    }
    .arrowLeft {
      display: flex;
      align-items: center;
      position: relative;
      left: 6vw;
      z-index: 30;
    }
    .arrowRight {
      display: flex;
      align-items: center;
      position: relative;
      right: 7vw;
      z-index: 30;
    }

    .testimonial-slider {
      .slick-track {
        padding: 14px 0;
      }
    }

    @media only screen and (max-width: 660px) {
      width: 100%;
      margin-top: -200px;
      flex-direction: column;
      margin-bottom: 180px;
      height: 500px;
    }

    @media only screen and (max-width: 380px) {
      width: 100%;
      margin-top: -220px;
      flex-direction: column;
      margin-bottom: 340px;
      height: 500px;
    }
  }
  button {
    width: 225px;
    height: 60px;
    margin-top: 40px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    background: linear-gradient(#0d8bc1, #19569c);
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 23px;
    line-height: 30px;
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
  }
}
