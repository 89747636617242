@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

.form_container {
  overflow: visible;

  .MuiPaper-root {
    overflow: visible;

  }

  .MuiDialog-paper {
    overflow: visible;
  }
}

.modal_container {
  position: relative;
  padding: 36px 100px 30px;
  overflow-y: auto;
  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  .promot{
    padding: 36px 70px 30px;
  }

  h2 {
    text-align: center;
    font-size: 40px;
    font-weight: 300;
    margin-top: -5px;
    margin-bottom: 18px;
  }

  .title_success {
    text-align: center;
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 0;
    color: #19569c;
  }

  @media screen and (max-width: 980px) {
    padding: 50px;
    h2 {
      font-size: 34px;
    }
  }
  @media screen and (max-width: 600px) {
    overflow-y: auto;
    h2 {
      font-size: 28px;
    }
    .title_success {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 460px) {
    overflow-y: auto;
    padding: 20px;
    max-height: calc(80vh + 35px);
    h2 {
      font-size: 25px;
    }
    .title_success {
      font-size: 25px;
    }
  }
}

.modal_containerpromot {
  position: relative;
  padding: 0 5px;
  overflow-y: auto;
  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  h2 {
    text-align: center;
    font-size: 40px;
    font-weight: 300;
    margin-top: -5px;
    margin-bottom: 18px;
  }

  .title_success {
    text-align: center;
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 0;
    color: #19569c;
  }

  @media screen and (max-width: 980px) {
    padding: 50px;
    h2 {
      font-size: 34px;
    }
  }
  @media screen and (max-width: 600px) {
    overflow-y: auto;
    h2 {
      font-size: 28px;
    }
    .title_success {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 460px) {
    overflow-y: auto;
    padding: 20px;
    max-height: calc(80vh + 35px);
    h2 {
      font-size: 25px;
    }
    .title_success {
      font-size: 25px;
    }
  }
}

.form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: 500px) {
    margin-top: 10px;
  }
}

.inputs_col {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;

  & > * {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  h6 {
    font-size: 13px;
    line-height: 18px;
    max-width: 100%;

    a {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 500px) {
    & > * {
      flex: 0 0 100%;
      max-width: 100%;
    }

      gap: 10px;
  
  }
}

.submit {
  font-family: inherit !important;
  color: white !important;
  width: calc(50% - 20px);
  height: 40px;
  font-size: 16px !important;
  font-weight: 500;
  background: linear-gradient(45deg, #272d83, #009bc5);
  border-radius: 20px !important;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.phone_wrap {
  display: flex;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  align-items: center;
  gap: 50px;
  text-decoration: none;
  justify-content: center;

  .phone_number {
    font-size: 30px;
    font-weight: 400;
  }

  img {
    position: absolute;
    left: 0;
    transform: translateX(-150%);
  }

  @media screen and (min-width: 1600px) {
    img {
      width: 40px;
      height: 40px;
    }
    .phone_number {
      font-size: 36px;
    }
  }
  @media screen and (max-width: 830px) {

    img {
      width: 24px;
      height: 24px;
    }

    .phone_number {
      font-size: 24px;
    }

  }
}

